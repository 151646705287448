import React, { useEffect } from 'react';
import { useClientContext } from '@cognitiv/galaxy-api';
import { Content } from 'components/layout/content/Content';
import { Navigation } from 'components/layout/navigation/Navigation';
import { Menu } from 'components/menu/Menu';
import { selectSettings } from 'ducks/settings/selectors';
import { ManageTaurusWorkspace } from 'products/taurus/modals/manage-taurus-workspace/ManageTaurusWorkspace';
import { setTaurusColumnTypesList } from 'products/taurus/operators/column-types-list/slices';
import { setTaurusCompressionFormatsList } from 'products/taurus/operators/compression-formats-list/slices';
import { setTaurusDataTypesList } from 'products/taurus/operators/data-types-list/slices';
import { setTaurusEvaluationAlgorithmsList } from 'products/taurus/operators/evaluation-algorithms-list/slices';
import { setTaurusFilterOperatorTypesList } from 'products/taurus/operators/filter-operator-types-list/slices';
import { setTaurusItemStatesList } from 'products/taurus/operators/item-states-list/slices';
import { setTaurusItemTypesList } from 'products/taurus/operators/item-types-list/slices';
import { setTaurusLabelTypesList } from 'products/taurus/operators/label-types-list/slices';
import { setTaurusOneHotMappingFeaturesList } from 'products/taurus/operators/one-hot-mapping-features-list/slices';
import { setTaurusOutputTypesList } from 'products/taurus/operators/output-types-list/slices';
import { setTaurusPartitionCollisionResolutionsList } from 'products/taurus/operators/partition-collision-resolutions-list/slices';
import { setTaurusPipelineEventsList } from 'products/taurus/operators/pipeline-events-list/slices';
import { setTaurusPipelineStatesList } from 'products/taurus/operators/pipeline-states-list/slices';
import { setTaurusQueryEnginesList } from 'products/taurus/operators/query-engines-list/slices';
import { setTaurusRowTypesList } from 'products/taurus/operators/row-types-list/slices';
import { setTaurusSearchAlgorithmsList } from 'products/taurus/operators/search-algorithms-list/slices';
import { setTaurusSortDirectionsList } from 'products/taurus/operators/sort-directions-list/slices';
import { setTaurusSplitMethodsList } from 'products/taurus/operators/split-methods-list/slices';
import { setTaurusSqlEnginesList } from 'products/taurus/operators/sql-engines-list/slices';
import { setTaurusSqlTemplateEnginesList } from 'products/taurus/operators/sql-template-engines-list/slices';
import { ManageTaurusAdvancedFiltering } from 'products/taurus/overlays/manage-taurus-advanced-filtering/ManageTaurusAdvancedFiltering';
import { ManageTaurusWorkspaces } from 'products/taurus/overlays/manage-taurus-workspaces/ManageTaurusWorkspaces';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'products/taurus/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { taurus } = useAppSelector(selectSettings);
  const { Taurus, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        const [
          taurus_item_types_list,
          taurus_column_types_list,
          taurus_item_states_list,
          taurus_compression_formats_list,
          taurus_data_types_list,
          taurus_feature_one_hot_mappings,
          taurus_label_types_list,
          taurus_output_types_list,
          taurus_row_types_list,
          taurus_sort_directions_list,
          taurus_split_methods_list,
          taurus_sql_engines_list,
          taurus_query_engines_list,
          taurus_search_algorithms_list,
          taurus_evaluation_algorithms_list,
          taurus_pipeline_events_list,
          taurus_sql_template_engines_list,
          taurus_pipeline_states_list,
          taurus_filter_operator_types_list,
          taurus_partition_collision_resolutions_list,
        ] = await Promise.all([
          Taurus.getTaurusItemTypesList(),
          Taurus.getTaurusColumnTypesList(),
          Taurus.getTaurusItemStatesList(),
          Taurus.getTaurusCompressionFormatsList(),
          Taurus.getTaurusDataTypesList(),
          Taurus.getTaurusOneHotMappingFeatures(),
          Taurus.getTaurusLabelTypesList(),
          Taurus.getTaurusOutputTypesList(),
          Taurus.getTaurusRowTypesList(),
          Taurus.getTaurusSortDirectionsList(),
          Taurus.getTaurusSplitMethodsList(),
          Taurus.getTaurusSqlEnginesList(),
          Taurus.getTaurusQueryEnginesList(),
          Taurus.getTaurusSearchAlgorithmsList(),
          Taurus.getTaurusEvaluationAlgorithmsList(),
          Taurus.getTaurusPipelineEventTypesList(),
          Taurus.getTaurusSqlTemplateEnginesList(),
          Taurus.getTaurusPipelineStatesList(),
          Taurus.getTaurusFilterOperatorTypesList(),
          Taurus.getTaurusPartitionCollisionResolutionsList(),
        ]);

        batch(() => {
          dispatch(setTaurusItemTypesList(taurus_item_types_list));
          dispatch(setTaurusColumnTypesList(taurus_column_types_list));
          dispatch(setTaurusItemStatesList(taurus_item_states_list));
          dispatch(setTaurusCompressionFormatsList(taurus_compression_formats_list));
          dispatch(setTaurusDataTypesList(taurus_data_types_list));
          dispatch(setTaurusOneHotMappingFeaturesList(taurus_feature_one_hot_mappings));
          dispatch(setTaurusLabelTypesList(taurus_label_types_list));
          dispatch(setTaurusOutputTypesList(taurus_output_types_list));
          dispatch(setTaurusRowTypesList(taurus_row_types_list));
          dispatch(setTaurusSortDirectionsList(taurus_sort_directions_list));
          dispatch(setTaurusSplitMethodsList(taurus_split_methods_list));
          dispatch(setTaurusSqlEnginesList(taurus_sql_engines_list));
          dispatch(setTaurusQueryEnginesList(taurus_query_engines_list));
          dispatch(setTaurusSearchAlgorithmsList(taurus_search_algorithms_list));
          dispatch(setTaurusEvaluationAlgorithmsList(taurus_evaluation_algorithms_list));
          dispatch(setTaurusPipelineEventsList(taurus_pipeline_events_list));
          dispatch(setTaurusSqlTemplateEnginesList(taurus_sql_template_engines_list));
          dispatch(setTaurusPipelineStatesList(taurus_pipeline_states_list));
          dispatch(setTaurusFilterOperatorTypesList(taurus_filter_operator_types_list));
          dispatch(setTaurusPartitionCollisionResolutionsList(taurus_partition_collision_resolutions_list));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (taurus) {
      getData();
    }
  }, [dispatch, Taurus, taurus, handleError]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Navigation />
        <Outlet />
        <ManageTaurusAdvancedFiltering />
        <ManageTaurusWorkspaces />
        <ManageTaurusWorkspace />
      </Content>
    </div>
  );
};

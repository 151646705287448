import React, { useCallback, useRef } from 'react';
import { Action, Button, CaptureClick, DropdownCalendar, Form, Icon, Label, Switch } from '@cognitiv/cassiopeia-ui';
import { getLocalTimeZone } from '@cognitiv/cicada-ui';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import moment from 'moment';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { taurus_max_results_list_default } from 'products/taurus/operators/max-results-list/defaults';
import { useAdvancedFiltering } from 'products/taurus/overlays/manage-taurus-advanced-filtering/hooks/useAdvancedFiltering';
import { useLocation } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'products/taurus/overlays/manage-taurus-advanced-filtering/ManageTaurusAdvancedFiltering.module.scss';

const date_range = ['Custom', 'Today', 'Yesterday', 'Current Week', 'Last Week', 'Current Month'];
const theme = { button: { padding: '4px 16px' } };

export const ManageTaurusAdvancedFiltering = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { pathname } = useLocation();
  const { form, onChange, clearFilter, setFilter, setDateRange } = useAdvancedFiltering();

  const { is_open } = useAppSelector((state) => selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_ADVANCED_FILTERING));

  const onClose = useCallback(() => {
    dispatch(updateModal({ [TAURUS_MODALS.MANAGE_TAURUS_ADVANCED_FILTERING]: { is_open: false } }));
  }, [dispatch]);

  const is_workspace = pathname.includes(TAURUS.WORKSPACES.path);
  const is_pipeline_definition = pathname.includes(TAURUS.PIPELINE_DEFINITIONS.path);
  const is_dataset = pathname.includes(TAURUS.DATASETS.path);

  if (!is_open) return null;

  return (
    <CaptureClick ref={ref} onClickOutside={onClose}>
      <div ref={ref} className={cn.overlay}>
        <div className={cn.header}>
          <div className={cn.title}>
            <h2>Filters</h2>
          </div>
          <div className={cn.close} onClick={onClose}>
            <Icon icon={faTimes} theme={{ icon: { color: 'inherit', font_size: '15px' } }} />
          </div>
        </div>
        <div className={cn.break} />
        <Form>
          <div className={cn.body}>
            {!is_workspace && (
              <>
                <DropdownCalendar
                  label={`Start Date (${getLocalTimeZone()})`}
                  onSelect={([start_date]) => onChange({ start_date: moment(start_date).format('MM/DD/YYYY hh:mm A'), range_name: 'Custom' })}
                  value={[form.start_date || null]}
                  format_date="MM/DD/YYYY"
                  format_hour="hh"
                  format_min="mm"
                />
                <DropdownCalendar
                  label={`Finish Date (${getLocalTimeZone()})`}
                  onSelect={([finish_date]) => onChange({ finish_date: moment(finish_date).format('MM/DD/YYYY hh:mm A'), range_name: 'Custom' })}
                  value={[form.finish_date || null]}
                  padding="16px 0px 0px 0px"
                  format_date="MM/DD/YYYY"
                  format_hour="hh"
                  format_min="mm"
                />
                <Label label="Date Range" margin="16px 0px 4px 0px" />
                <div className={cn.row}>
                  {date_range.map((date) => (
                    <Button
                      key={date}
                      height={24}
                      min_width="fit-content"
                      theme={theme}
                      variant={form.range_name === date ? 'primary' : 'tertiary'}
                      onClick={() => setDateRange(date)}
                    >
                      {date}
                    </Button>
                  ))}
                </div>
              </>
            )}
            <Label label="Max Results" margin="16px 0px 4px 0px" />
            <div className={cn.row}>
              {taurus_max_results_list_default.map((row) => (
                <Button
                  key={row.max_row}
                  height={24}
                  min_width="fit-content"
                  theme={theme}
                  variant={form.max_rows === row.max_row ? 'primary' : 'tertiary'}
                  onClick={() => onChange({ max_rows: row.max_row })}
                >
                  {row.max_row}
                </Button>
              ))}
            </div>
            {!is_workspace && (
              <>
                <Label label="Workspace Filters" margin="16px 0px 4px 0px" />
                <div className={cn.row}>
                  <Switch
                    onClick={(include_parent_workspaces) => onChange({ include_parent_workspaces })}
                    checked={form.include_parent_workspaces}
                    width="100%"
                  >
                    Include Parents
                  </Switch>
                  <Switch
                    width="100%"
                    onClick={(include_child_workspaces) => onChange({ include_child_workspaces })}
                    checked={form.include_child_workspaces}
                  >
                    Include Children
                  </Switch>
                </div>
              </>
            )}
            <Label label="Status Filters" margin="16px 0px 4px 0px" />
            <div className={cn.row}>
              {is_pipeline_definition && (
                <Switch onClick={(is_scheduled) => onChange({ is_scheduled })} width="100%" checked={form.is_scheduled}>
                  Scheduled
                </Switch>
              )}
              {is_dataset && (
                <Switch onClick={(include_unmanaged) => onChange({ include_unmanaged })} width="100%" checked={form.include_unmanaged}>
                  Include Unmanaged
                </Switch>
              )}
              <Switch onClick={(include_archived) => onChange({ include_archived })} width="100%" checked={form.include_archived}>
                Include Archived
              </Switch>
              <Switch onClick={(sort_by_cost) => onChange({ sort_by_cost })} width="100%" checked={form.sort_by_cost}>
                Sort By Cost
              </Switch>
            </div>
            <Label label="User Filters" margin="16px 0px 4px 0px" />
            <Switch onClick={(created_by_me) => onChange({ created_by_me })} checked={form.created_by_me} width="100%">
              Created By Me
            </Switch>
          </div>
        </Form>
        <div className={cn.footer}>
          <Action onClick={clearFilter}>Clear Filters</Action>
          <Button onClick={setFilter}>Ok</Button>
        </div>
      </div>
    </CaptureClick>
  );
};

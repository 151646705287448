import React, { FormEvent, useState } from 'react';
import { Button, InputText } from '@cognitiv/cassiopeia-ui';
import { getValidationError } from '@cognitiv/cicada-ui';
import { SERVICES, useClientContext } from '@cognitiv/galaxy-api';
import { updateSettings } from 'ducks/settings/slices';

import { selectSettings } from 'ducks/settings/selectors';
import { selectUser } from 'ducks/user/selectors';
import { setUser } from 'ducks/user/slices';
import { useValidate } from 'hooks/useValidate';
import { validation } from 'products/public/pages/login/validation';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'products/public/pages/login/LoginPage.module.scss';
import { UserPayload } from 'ducks/user/types';
import { normalizeUser } from 'ducks/user/normalizers';

const { PUBLIC_URL } = process.env;

export const initial_state: UserPayload = {
  email_address: '',
  password: '',
};

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector(selectSettings);
  const user = useAppSelector(selectUser);
  const { Sol, persistKeys } = useClientContext();

  const [form, setForm] = useState<UserPayload>({ ...initial_state });

  const { validate, resetError, errors } = useValidate(form, validation);

  const onChange = (item: Partial<UserPayload>) => {
    resetError(item);
    dispatch(setUser({ error: null }));
    setForm((prev) => ({ ...prev, ...item }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const is_valid = validate();

      if (!is_valid) return;
      const data = await Sol.authenticate({
        email_address: form.email_address,
        password: form.password,
        required_service_ids: [SERVICES.TAURUS],
      });

      persistKeys({ public_key: data.public_key, private_key: data.private_key });

      const user = normalizeUser(data);
      dispatch(setUser(user));
    } catch (err: any) {
      dispatch(updateSettings({ loading: false }));
      dispatch(setUser({ error: err?.message }));
    }
  };

  return (
    <div className={cn.page}>
      <form className={cn.login} onSubmit={onSubmit}>
        <div className={cn.logo}>
          <img src={`${PUBLIC_URL}/cogn-logo-${theme}.svg`} alt="Cognitiv Logo" />
        </div>
        <InputText
          auto_focus
          padding="36px 0px 0px 0px"
          placeholder="Username"
          value={form.email_address}
          onChange={(item) => onChange({ email_address: item.value })}
          error={getValidationError({ errors, key: 'email_address' })}
        />
        <InputText
          type="password"
          placeholder="Password"
          padding="16px 0px 16px 0px"
          value={form.password}
          onChange={(item) => onChange({ password: item.value })}
          error={getValidationError({ errors, key: 'password' })}
        />
        <div className={cn.errorWrapper}>{user.error && <p className={cn.error}>{user.error}</p>}</div>
        <Button type="submit" min_width="100%" margin="16px 0px 0px 0px">
          Log in
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;

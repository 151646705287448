import { TaurusFeatureV2 } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusFeatureV2[] = [];

export const taurusFeaturesListV2Slice = createSlice({
  name: SLICE.TAURUS_FEATURES_LIST_V2,
  initialState,
  reducers: {
    clearTaurusFeaturesListV2: () => initialState,
    setTaurusFeaturesListV2: (_, action: PayloadAction<TaurusFeatureV2[]>) => action.payload,
  },
});

export const { clearTaurusFeaturesListV2, setTaurusFeaturesListV2 } = taurusFeaturesListV2Slice.actions;

export default taurusFeaturesListV2Slice.reducer;

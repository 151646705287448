import { TaurusEtlItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_item_etl_default } from 'products/taurus/operators/item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusEtlItem = { ...taurus_item_etl_default };

export const taurusEtlItemSlice = createSlice({
  name: SLICE.TAURUS_ETL_ITEM,
  initialState,
  reducers: {
    clearTaurusEtlItem: () => initialState,
    setTaurusEtlItem: (_, action: PayloadAction<TaurusEtlItem>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusEtlItem, setTaurusEtlItem } = taurusEtlItemSlice.actions;

export default taurusEtlItemSlice.reducer;

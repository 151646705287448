import { parameter_default } from 'products/taurus/components/parameters/constants';
import { TAURUS_MODAL_VIEW } from 'products/taurus/modals/enums';
import { TaurusModals } from 'products/taurus/modals/types';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { taurus_transform_column_default } from 'products/taurus/operators/transform-item/defaults';

export const taurus_modals: TaurusModals = {
  manage_taurus_workspace: { is_open: false, workspace_id: null, parent_workspace_id: null, is_navigation: false },
  manage_taurus_workspaces: { is_open: false, workspace_id: TAURUS_ROOT, current_workspace_id: TAURUS_ROOT, update: null },
  run_taurus_pipeline_definition: { is_open: false },
  select_taurus_item_type: { is_open: false },
  manage_pipeline_definition: { is_open: false },
  copy_taurus_pipeline: { is_open: false },
  create_taurus_pipeline_definition: { is_open: false },
  manage_taurus_query_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE, show_parents: false },
  manage_taurus_non_etl_query_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE, show_parents: false },
  manage_taurus_projection_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE },
  manage_taurus_projection_v2_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE },
  manage_taurus_dataset_read_item: { is_open: false, read_only: false },
  manage_taurus_dataset_write_item: { is_open: false, read_only: false },
  manage_taurus_predict_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE },
  manage_taurus_transform_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE },
  manage_taurus_train_item: { is_open: false, read_only: false },
  manage_taurus_publish_item: { is_open: false, read_only: false },
  manage_taurus_strategy_publisher_item: { is_open: false, read_only: false },
  manage_taurus_evaluate_item: { is_open: false, read_only: false },
  manage_taurus_custom_etl_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE },
  manage_taurus_import_item: { is_open: false, read_only: false, view: TAURUS_MODAL_VIEW.CREATE },
  manage_taurus_dataset: { is_open: false, view: TAURUS_MODAL_VIEW.CREATE, read_only: false },
  manage_taurus_sql_template: { is_open: false, view: TAURUS_MODAL_VIEW.CREATE, read_only: false },
  manage_taurus_pipeline_definition: { is_open: false },
  select_taurus_dataset: { is_open: false, onSubmit: () => {} },
  view_taurus_statistics: { is_open: false },
  preview_taurus_query_sql: { is_open: false },
  preview_taurus_dataset: { is_open: false },
  parse_taurus_template: { is_open: false },
  manage_taurus_pipeline_priority: { is_open: false },
  restore_taurus_item_objects: { is_open: false },
  manage_taurus_advanced_filtering: { is_open: false },
  manage_taurus_transform_column_mapping: { is_open: false, column: { ...taurus_transform_column_default }, onSubmit: () => {} },
  select_taurus_publish_model: { is_open: false, workspace_id: TAURUS_ROOT, model_id: null, onSelect: () => {} },
  manage_taurus_pipeline_schedule: { is_open: false },
  manage_taurus_pipeline: { is_open: false },
  select_taurus_pipeline_definition: { is_open: false },
  manage_taurus_one_hot_mapping: { is_open: false },
  manage_taurus_model: { is_open: false },
  select_taurus_source_item: { is_open: false, workspace_id: TAURUS_ROOT, onSelect: () => {} },
  manage_taurus_source_item: { is_open: false },
  manage_taurus_item_configuration: { is_open: false, read_only: false },
  manage_taurus_custom_item: { is_open: false, read_only: false },
  manage_taurus_query_executions: { is_open: false },
  manage_taurus_parent_items: { is_open: false },
  select_taurus_parent_items: { is_open: false },
  manage_taurus_parameter_value: { is_open: false, parameter: { ...parameter_default }, read_only: false, onSubmit: () => {} },
  manage_taurus_view_query: { is_open: false, title: '', value: '' },
  view_taurus_dataset_partitions: { is_open: false },
  view_taurus_item_error: { is_open: false, title: '', error: '' },
};

import { TaurusEtlItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusEtlItem[] = [];

export const taurusEtlItemsSlice = createSlice({
  name: SLICE.TAURUS_ETL_ITEMS,
  initialState,
  reducers: {
    clearTaurusEtlItems: () => initialState,
    setTaurusEtlItems: (_, action: PayloadAction<TaurusEtlItem[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusEtlItems, setTaurusEtlItems } = taurusEtlItemsSlice.actions;

export default taurusEtlItemsSlice.reducer;

import { TaurusProjectionItem, TaurusProjectionItemColumn, TaurusProjectionItemColumnFilter } from '@cognitiv/galaxy-api';
import { TAURUS_COMPRESSION_TYPE, TAURUS_COMPRESSION_TYPE_NAME } from 'products/taurus/operators/compression-formats-list/enums';
import { TAURUS_DATA_TYPE, TAURUS_DATA_TYPE_NAME } from 'products/taurus/operators/data-types-list/enums';
import {
  TAURUS_FILTER_OPERATOR,
  TAURUS_FILTER_OPERATOR_CONSTRAINT,
  TAURUS_FILTER_OPERATOR_NAME,
} from 'products/taurus/operators/filter-operator-types-list/enums';
import { TAURUS_OUTPUT_TYPE, TAURUS_OUTPUT_TYPE_NAME } from 'products/taurus/operators/output-types-list/enums';

export const taurus_projection_item_default: TaurusProjectionItem = {
  dimensions: [],
  columns: [],
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: '',
  is_definition: false,
  parent_item_ids: [],
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  dataset_id: 0,
  definition_item_id: null,
  s3_bucket: '',
  s3_prefix: '',
  glue_table_name: null,
  output_type_name: TAURUS_OUTPUT_TYPE_NAME[TAURUS_OUTPUT_TYPE.PARQUET],
  output_type_id: TAURUS_OUTPUT_TYPE.PARQUET,
  output_compression_format_name: TAURUS_COMPRESSION_TYPE_NAME[TAURUS_COMPRESSION_TYPE.SNAPPY],
  output_compression_format_id: TAURUS_COMPRESSION_TYPE.SNAPPY,
  row_count: 0,
  row_count_formatted: '0',
  created_datetime_formatted: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  partition_column_id: null,
  partition_column_name: '',
  partition_name: null,
  partition_count: null,
  row_type_id: null,
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  saved: false,
  uuid: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};

export const taurus_projection_column_filter_default: TaurusProjectionItemColumnFilter = {
  column_filter_id: 0,
  filter_operator_type_id: TAURUS_FILTER_OPERATOR.IS_NULL,
  filter_operator_type_name: TAURUS_FILTER_OPERATOR_NAME[TAURUS_FILTER_OPERATOR.IS_NULL],
  operand_count_constraint: TAURUS_FILTER_OPERATOR_CONSTRAINT[TAURUS_FILTER_OPERATOR.IS_NULL],
  column_id: 0,
  operands: [],
  data_type_id: TAURUS_DATA_TYPE.STRING,
  data_type_name: TAURUS_DATA_TYPE_NAME[TAURUS_DATA_TYPE.STRING],
  uuid: '',
};

export const taurus_projection_column_default: TaurusProjectionItemColumn = {
  feature_name: '',
  unique_feature_name: '',
  filters: [],
  column_id: 0,
  dataset_id: null,
  column_type_id: 0,
  column_type_name: '',
  column_name: '',
  feature_identifier: '',
  extended_data: '',
  label_type_id: null,
  label_type_name: '',
  label_group: '',
  data_type_id: null,
  data_type_name: '',
  partition_on: false,
  partition_name: null,
  partition_count: null,
  is_epoch_column: false,
  is_array: false,
  array_length: null,
  array_length_formatted: '',
  column_index: null,
  column_index_formatted: '',
  partition_count_formatted: '',
  saved: false,
  uuid: '',
};

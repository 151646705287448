import { TaurusProjectionV2Item } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_projection_v2_item_default } from 'products/taurus/operators/projection-v2-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusProjectionV2Item>;
}

const initialState: TaurusProjectionV2Item = { ...taurus_projection_v2_item_default };

export const taurusProjectionV2ItemSlice = createSlice({
  name: SLICE.TAURUS_PROJECTION_V2_ITEM,
  initialState,
  reducers: {
    updateTaurusProjectionV2Item: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusProjectionV2Item: () => initialState,
    setTaurusProjectionV2Item: (_, action: PayloadAction<TaurusProjectionV2Item>) => action.payload,
  },
});

export const { updateTaurusProjectionV2Item, clearTaurusProjectionV2Item, setTaurusProjectionV2Item } = taurusProjectionV2ItemSlice.actions;

export default taurusProjectionV2ItemSlice.reducer;
